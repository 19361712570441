<template>
  <div>
    <table-header
      :is-drilldown="false"
      :fields="fields"
      :data="opportunities"
      @export-pdf="exportPDF(columns, body, 'opportunities.pdf')"
      @search="searchOpportunity"
    >
      <template #update-button>
        <b-button
          v-if="isEditing"
          size="is-small"
          icon-left="content-save"
          type="is-success"
          @click="updateOpportunity"
        >
          save
        </b-button>
      </template>

      <template #reset-button>
        <b-button
          v-if="Object.keys(filters).length > 0"
          size="is-small"
          icon="power-off"
          @click="resetFilters"
        >
          Reset Filters
        </b-button>
      </template>

      <template #customize>
        <b-dropdown
          aria-role="list"
          postition="is-bottom-left"
        >
          <b-button
            slot="trigger"
            size="is-small"
            icon-left="wrench"
            icon-right="chevron-down"
          >
            grids
          </b-button>

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isBordered"
                size="is-small"
              >
                Bordered
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isCheckable"
                size="is-small"
              >
                Checkable
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isNarrowed"
                size="is-small"
              >
                Narrowed
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.stickyHeaders"
                size="is-small"
              >
                Sticky headers
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isScrollable"
                size="is-small"
              >
                Scrollable
              </b-checkbox>
            </b-field>
          </b-dropdown-item>

          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isStriped"
                size="is-small"
              >
                Striped
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <template #hide-show-columns>
        <b-dropdown
          aria-role="list"
          postition="is-bottom-left"
          :scrollable="true"
          :max-height="300"
          :close-on-click="false"
          class="dg-columns"
        >
          <b-button
            slot="trigger"
            icon-left="eye"
            size="is-small"
            icon-right="chevron-down"
          >
            columns
          </b-button>
          <draggable
            v-model="columns"
            group="columns"
            handle=".handle"
            @start="drag=true"
            @end="drag=false"
          >
            <b-dropdown-item
              v-for="(column, index) in columns"
              :key="index"
              aria-role="listitem"
            >
              <b-field>
                <b-checkbox
                  :id="column.field"
                  v-model="column.visible"
                  size="is-small"
                >
                  {{ column.label }}
                </b-checkbox>
                <i class="grip-vertical handle" />
              </b-field>
            </b-dropdown-item>
          </draggable>
          <hr class="dropdown-divider">
        </b-dropdown>
      </template>
    </table-header>

    <b-table
      ref="opportunitiesTable"
      :mobile-cards="false"
      :loading="loading"
      :data="opportunities"
      :checkable="customizations.isCheckable"
      :checked-rows.sync="checkedRows"
      paginated
      backend-pagination
      :total="total"
      :per-page="perPage"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      :narrowed="customizations.isNarrowed"
      :bordered="customizations.isBordered"
      :striped="customizations.isStriped"
      :sticky-header="customizations.stickyHeaders"
      :scrollable="customizations.isScrollable"
      backend-filtering
      :debounce-search="800"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      :sort-icon="sortIcon"
      :sort-icon-size="sortIconSize"
      @sort="onSort"
      @filters-change="onFilter"
      @page-change="onPageChange"
    >
      <b-table-column
        v-for="(column, index) in columns"
        :key="index"
        :label="column.label"
        :field="column.field"
        :searchable="column.searchable"
        :sortable="column.sortable"
        :visible="column.visible"
      >
        <template
          slot="searchable"
          slot-scope="props"
        >
          <template v-if="column.field === 'stage'">
            <b-select
              v-model="props.filters[`${column.searchField}`]"
              size="is-small"
            >
              <option
                v-for="(stage, stageIndex) in opportunityStages"
                :key="stageIndex"
                :value="stage.id"
              >
                {{ stage.value }}
              </option>
            </b-select>
          </template>

          <template v-else-if="column.field === 'temperature'">
            <b-select
              v-model="props.filters[`${column.searchField}`]"
              size="is-small"
            >
              <option
                v-for="(temperature, temperatureIndex) in temperatures"
                :key="temperatureIndex"
                :value="temperature.id"
              >
                {{ temperature.value }}
              </option>
            </b-select>
          </template>

          <template v-else-if="column.field === 'estimatedPotentials.probability'">
            <b-select
              v-model="props.filters[`${column.searchField}`]"
              size="is-small"
            >
              <option
                v-for="option in probabilities"
                :key="option.id"
                :value="option.code"
              >
                {{ option.value }}
              </option>
            </b-select>
          </template>

          <template
            v-else-if="column.field === 'estimatedPotentials.estimatedRevenue'
              || column.field === 'estimatedPotentials.estimatedGrossProfit'"
          >
            <b-input
              v-model="props.filters[`${column.searchField}`]"
              placeholder="Search..."
              icon="search"
              size="is-small"
              type="number"
            />
          </template>

          <template v-else>
            <b-input
              v-model="props.filters[`${column.searchField}`]"
              placeholder="Search..."
              icon="search"
              size="is-small"
            />
          </template>
        </template>

        <template v-slot="props">
          <template v-if="column.field === 'actions'">
            <div class="buttons">
              <b-tooltip
                type="is-info"
                label="Add Activity"
                position="is-right"
              >
                <b-dropdown
                  aria-role="list"
                  append-to-body
                >
                  <b-button
                    slot="trigger"
                    type="is-text"
                    class="has-text-grey"
                    icon-right="plus"
                  />
                  <b-dropdown-item
                    aria-role="listitem"
                    class="has-text-grey"
                    @click="addActivity(props.row.id, props.row.name, 165)"
                  >
                    <b-icon icon="stopwatch" />
                    <span class="has-text-weight-semibold">Task</span>
                  </b-dropdown-item>

                  <hr class="dropdown-divider">

                  <b-dropdown-item
                    aria-role="listitem"
                    class="has-text-grey"
                    @click="addActivity(props.row.id, props.row.name, 164)"
                  >
                    <b-icon icon="sticky-note" />
                    <span class="has-text-weight-semibold">Note</span>
                  </b-dropdown-item>

                  <hr class="dropdown-divider">

                  <b-dropdown-item
                    aria-role="listitem"
                    class="has-text-grey"
                    @click="addActivity(props.row.id, props.row.name, 166)"
                  >
                    <b-icon icon="calendar-month" />
                    <span class="has-text-weight-semibold">Event</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-tooltip>

              <b-tooltip
                type="is-info"
                label="Edit"
                position="is-right"
              >
                <b-button
                  tag="router-link"
                  :to="{ name: 'EditOpportunity', params: { opportunityId: props.row.id } }"
                  type="is-text"
                  class="has-text-grey"
                  icon-right="pencil"
                />
              </b-tooltip>

              <b-tooltip
                type="is-info"
                label="Email"
                position="is-right"
              >
                <b-button
                  type="is-text"
                  class="has-text-grey"
                  icon-right="email"
                  @click="sendMail(props.row.primaryContact.email)"
                />
              </b-tooltip>
            </div>
          </template>

          <template v-else-if="column.field === 'name'">
            <router-link
              class="has-text-weight-bold has-text-primary"
              :to="{
                name: 'ViewOpportunity',
                params: { opportunityId: props.row.id }
              }"
            >
              {{ props.row[column.field] }}
            </router-link>
          </template>

          <template v-else-if="column.field === 'account.name'">
            <b-field v-if="isEditing && props.row.id === selectedOpportunityId">
              <b-autocomplete
                v-model="account"
                size="is-small"
                field="name"
                icon-right="search"
                :data="accountsSearchResults"
                @typing="getAccountsAsyncData"
                @select="(option) => (selectedAccount = option)"
              >
                <template slot-scope="acc">
                  <div class="media">
                    <div class="media-content">
                      {{ acc.option.name }}
                      <br>
                      <small>
                        customer number: {{ acc.option.externalId }}
                      </small>
                    </div>
                  </div>
                </template>

                <template #empty>
                  No results for {{ account }}
                </template>
              </b-autocomplete>
            </b-field>

            <template v-else>
              <span
                v-if="props.row.account.name"
                class="is-pointer"
                @dblclick="onEdit(props.row)"
              >{{ props.row.account.name }}</span>
            </template>
          </template>

          <template v-else-if="column.field === 'primaryContact.firstName'">
            <b-field v-if="isEditing && props.row.id === selectedOpportunityId">
              <b-autocomplete
                v-model="primaryContact"
                size="is-small"
                :data="contactsSearchResults"
                field="fullName"
                icon-right="search"
                @typing="getContactsAsyncData"
                @select="(option) => (selectedContact = option)"
              >
                <template slot-scope="contact">
                  <div class="media">
                    <div class="media-left">
                      <img
                        width="32"
                        :src="contact.option.photo ? contact.option.photo : defaultImage"
                        :alt="contact.option.fullName"
                      >
                    </div>
                    <div class="media-content">
                      {{ contact.option.fullName.trim() }}
                      <br>
                      <small>
                        Phone: {{ contact.option.phone }}, Email:
                        <b>{{ contact.option.emailAddress }}</b>
                      </small>
                    </div>
                  </div>
                </template>

                <template #empty>
                  No results for {{ primaryContact }}
                </template>
              </b-autocomplete>
            </b-field>

            <template v-else>
              <span
                v-if="props.row.primaryContact"
                class="is-pointer"
                @dblclick="onEdit(props.row)"
              >
                {{
                  `
                  ${props.row.primaryContact.firstName}
                  ${props.row.primaryContact.lastName}
                  `.trim()
                }}
              </span>
            </template>
          </template>

          <template v-else-if="column.field === 'owners'">
            <template v-if="props.row[column.field]">
              <template v-for="(owner, ownerIndex) in props.row[column.field]">
                <template v-if="owner.owner">
                  <b-taglist
                    :key="ownerIndex"
                    attached
                  >
                    <b-tag>
                      {{ `
                    ${owner.owner.firstName}
                    ${owner.owner.middleName}
                    ${owner.owner.lastName}`.trim() }}
                    </b-tag>
                    <b-tag type="is-info">
                      {{ owner.percentage }} %
                    </b-tag>
                  </b-taglist>
                </template>
              </template>
            </template>
          </template>

          <template v-else-if=" column.field === 'estimatedPotentials.estimatedRevenue'">
            <b-field v-if="isEditing && props.row.id === selectedOpportunityId">
              <currency-input
                v-model="estimatedRevenue"
                class="input is-small"
                @change="setGrossProfitPercentage"
              />
            </b-field>

            <span
              v-else
              class="is-pointer"
              @dblclick="onEdit(props.row)"
            >
              {{ props.row.estimatedPotentials.estimatedRevenue | currency }}
            </span>
          </template>

          <template v-else-if=" column.field === 'estimatedPotentials.estGrossProfitPercentage'">
            <b-field v-if="isEditing && props.row.id === selectedOpportunityId">
              <b-input
                v-model="estGrossProfitPercentage"
                type="number"
                @input="setGrossProfit"
              />
            </b-field>

            <span
              v-else
              class="is-pointer"
              @dblclick="onEdit(props.row)"
            >
              {{ props.row.estimatedPotentials.estGrossProfitPercentage }}%
            </span>
          </template>

          <template v-else-if=" column.field === 'estimatedPotentials.estimatedGrossProfit'">
            <b-field v-if="isEditing && props.row.id === selectedOpportunityId">
              <currency-input
                v-model="estimatedGrossProfit"
                class="input is-small"
              />
            </b-field>

            <span
              v-else
              class="is-pointer"
              @dblclick="onEdit(props.row)"
            >
              {{ props.row.estimatedPotentials.estimatedGrossProfit| currency }}
            </span>
          </template>

          <template v-else-if="column.field === 'estimatedPotentials.probability'">
            <b-field v-if="isEditing && props.row.id === selectedOpportunityId">
              <b-select
                v-model="probability"
                size="is-small"
              >
                <option
                  v-for="option in probabilities"
                  :key="option.id"
                  :value="option.code"
                >
                  {{ option.value }}
                </option>
              </b-select>
            </b-field>

            <template v-else>
              <span
                v-if="props.row.estimatedPotentials.probability"
                class="is-pointer"
                @dblclick="onEdit(props.row)"
              >
                {{ props.row.estimatedPotentials.probability / 100 | percent }}
              </span>
            </template>
          </template>

          <template v-else-if="column.field === 'estimatedPotentials.bidDueDate'">
            <b-field v-if="isEditing && props.row.id === selectedOpportunityId">
              <b-input
                v-model="bidDueDate"
                size="is-small"
                type="date"
                icon="calendar-month"
              />
            </b-field>

            <span
              v-else
              class="is-pointer"
              @dblclick="onEdit(props.row)"
            >
              <template v-if="props.row.estimatedPotentials.bidDueDate">
                {{ props.row.estimatedPotentials.bidDueDate | date }}
              </template>
            </span>
          </template>

          <template v-else-if="column.field === 'estimatedPotentials.estBookingDate'">
            <b-field v-if="isEditing && props.row.id === selectedOpportunityId">
              <b-input
                v-model="estBookingDate"
                size="is-small"
                type="date"
                icon="calendar-month"
              />
            </b-field>

            <span
              v-else
              class="is-pointer"
              @dblclick="onEdit(props.row)"
            >
              <template v-if="props.row.estimatedPotentials.estBookingDate">
                {{ props.row.estimatedPotentials.estBookingDate | date }}
              </template>
            </span>
          </template>

          <template v-else-if="column.field === 'estimatedPotentials.estInstallDate'">
            <b-field v-if="isEditing && props.row.id === selectedOpportunityId">
              <b-input
                v-model="estInstallDate"
                size="is-small"
                type="date"
                icon="calendar-month"
              />
            </b-field>

            <span
              v-else
              class="is-pointer"
              @dblclick="onEdit(props.row)"
            >
              <template v-if="props.row.estimatedPotentials.estInstallDate">
                {{ props.row.estimatedPotentials.estInstallDate | date }}
              </template>
            </span>
          </template>

          <template v-else-if="column.field === 'estimatedPotentials.estInvoiceDate'">
            <b-field v-if="isEditing && props.row.id === selectedOpportunityId">
              <b-input
                v-model="estInvoiceDate"
                size="is-small"
                type="date"
                icon="calendar-month"
              />
            </b-field>

            <span
              v-else
              class="is-pointer"
              @dblclick="onEdit(props.row)"
            >
              <template v-if="props.row.estimatedPotentials.estInvoiceDate">
                {{ props.row.estimatedPotentials.estInvoiceDate | date }}
              </template>
            </span>
          </template>

          <template v-else-if=" column.field === 'stage'">
            <b-field v-if="isEditing && props.row.id === selectedOpportunityId">
              <b-select
                v-model="stageId"
                size="is-small"
              >
                <option
                  v-for="(stage, stageIndex) in opportunityStages"
                  :key="stageIndex"
                  :value="stage.id"
                >
                  {{ stage.value }}
                </option>
              </b-select>
            </b-field>

            <template v-else>
              <span
                v-if="props.row[column.field]"
                class="is-pointer"
                @dblclick="onEdit(props.row)"
              >{{ props.row[column.field].value }}
              </span>
            </template>
          </template>

          <template v-else-if="column.field === 'temperature'">
            <b-field v-if="isEditing && props.row.id === selectedOpportunityId">
              <b-select
                v-model="temperatureId"
                size="is-small"
              >
                <option
                  v-for="(temperature, temperatureIndex) in temperatures"
                  :key="temperatureIndex"
                  :value="temperature.id"
                >
                  {{ temperature.value }}
                </option>
              </b-select>
            </b-field>

            <template v-else>
              <span
                v-if="props.row[column.field]"
                class="is-pointer"
                @dblclick="onEdit(props.row)"
              >{{ props.row[column.field].value }}</span>
            </template>
          </template>

          <template
            v-else-if="
              column.field === 'createdAt' ||
                column.field === 'updatedAt'
            "
          >
            <template v-if="props.row[column.field]">
              {{ props.row[column.field] | date }}
            </template>
          </template>

          <template v-else>
            {{ props.row[column.field] }}
          </template>
        </template>
      </b-table-column>

      <template slot="footer">
        <table-footer
          :first-item="firstItem"
          :page="page"
          :per-page="perPage"
          :total="total"
        >
          <template #page-dropdown>
            <b-dropdown
              v-model="perPage"
              aria-role="list"
              position="is-top-left"
            >
              <b-button
                slot="trigger"
                size="is-small"
                type="is-info"
                icon-right="chevron-down"
              >
                {{ perPage }}
              </b-button>

              <template v-for="(i, index) in pages">
                <b-dropdown-item
                  :key="index"
                  :value="i"
                >
                  {{ i }}
                </b-dropdown-item>
                <hr
                  :key="i"
                  class="dropdown-divider"
                >
              </template>
            </b-dropdown>
          </template>
        </table-footer>
      </template>

      <template slot="empty">
        <empty-table table-name="opportunities" />
      </template>
    </b-table>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';
import SearchMixin from '@/mixins/SearchMixin';

import {
  TableFooter, TableHeader, EmptyTable,
} from '@/components/Shared';

export default {
  name: 'OpportunitiesInfluencingTable',

  components: {
    TableFooter,
    TableHeader,
    EmptyTable,
    draggable,
  },

  mixins: [SearchMixin],

  data: () => ({
    checkedRows: [],

    total: 0,
    page: 1,
    perPage: 50,
    pages: [10, 25, 50, 100, 250, 500, 1000, 2000],

    sortField: 'createdAt',
    sortOrder: 'desc',
    defaultSortOrder: 'desc',
    sortIcon: 'menu-up',
    sortIconSize: 'is-small',

    isEditing: false,
    selectedOpportunityId: null,

    filters: {},

    searchClause: '',

    fields: {
      ID: 'formattedName',
      Name: 'name',
      Account: 'account.name',
      Contact: 'primaryContact.firstName',
      Owner: 'owners',
      Industry: 'account.companyInformation.industry.value',
      'Expected Revenue': 'estimatedPotentials.estimatedRevenue',
      'Estimated Gross Profit %': 'estimatedPotentials.estGrossProfitPercentage',
      'Estimated Gross Profit': 'estimatedPotentials.estimatedGrossProfit',
      Probability: 'estimatedPotentials.probability',
      Stage: 'stage.value',
      Temperature: 'temperature.value',
      'Bid Due Date': 'estimatedPotentials.bidDueDate',
      'Est Booking Date': 'estimatedPotentials.estBookingDate',
      'Est Install Date': 'estimatedPotentials.estInstallDate',
      'Est Invoice Date': 'estimatedPotentials.estInvoiceDate',
      'Created On': 'createdAt',
      'Modified On': 'updatedAt',
    },

    columns: [
      {
        label: 'Name',
        field: 'name',
        sortable: true,
        searchable: true,
        searchField: 'opportunityname',
        sticky: false,
        visible: true,
      }, {
        label: 'Account',
        field: 'account.name',
        sortable: true,
        searchable: true,
        searchField: 'accountname',
        sticky: false,
        visible: true,
      }, {
        label: 'Contact',
        field: 'primaryContact.firstName',
        sortable: true,
        searchable: true,
        searchField: 'contactname',
        sticky: false,
        visible: true,
      }, {
        label: 'Owners',
        field: 'owners',
        sortable: false,
        searchable: true,
        searchField: 'opportunityowner',
        sticky: false,
        visible: true,
      }, {
        label: 'Expected Revenue',
        field: 'estimatedPotentials.estimatedRevenue',
        sortable: true,
        searchable: false,
        searchField: 'estimatedrevenue',
        sticky: false,
        visible: true,
      }, {
        label: 'Estimated Gross Profit %',
        field: 'estimatedPotentials.estGrossProfitPercentage',
        sortable: true,
        searchable: true,
        searchField: 'estimatedgrossprofit',
        sticky: false,
        visible: true,
      }, {
        label: 'Estimated Gross Profit',
        field: 'estimatedPotentials.estimatedGrossProfit',
        sortable: true,
        searchable: true,
        searchField: 'estimatedgrossprofit',
        sticky: false,
        visible: true,
      }, {
        label: 'Probability',
        field: 'estimatedPotentials.probability',
        sortable: false,
        searchable: true,
        searchField: 'probability',
        sticky: false,
        visible: true,
      }, {
        label: 'Stage',
        field: 'stage',
        sortable: false,
        searchable: true,
        searchField: 'stageid',
        sticky: false,
        visible: true,
      }, {
        label: 'Temperature',
        field: 'temperature',
        sortable: false,
        searchable: true,
        searchField: 'temperatureid',
        sticky: false,
        visible: true,
      }, {
        label: 'Bid Due Date',
        field: 'estimatedPotentials.bidDueDate',
        sortable: true,
        searchable: false,
        searchField: 'bidduedate',
        sticky: false,
        visible: false,
      }, {
        label: 'Est Booking Date',
        field: 'estimatedPotentials.estBookingDate',
        sortable: true,
        searchable: false,
        searchField: 'estbookingdate',
        sticky: false,
        visible: false,
      }, {
        label: 'Est Install Date',
        field: 'estimatedPotentials.estInstallDate',
        sortable: true,
        searchable: false,
        searchField: 'estinstalldate',
        sticky: false,
        visible: false,
      }, {
        label: 'Est Invoice Date',
        field: 'estimatedPotentials.estInvoiceDate',
        sortable: true,
        searchable: false,
        searchField: 'estinvoicedate',
        sticky: false,
        visible: false,
      }, {
        label: 'Created On',
        field: 'createdAt',
        sortable: true,
        searchable: false,
        searchField: 'createdAt',
        sticky: false,
        visible: false,
      }, {
        label: 'Modified On',
        field: 'updatedAt',
        sortable: true,
        searchable: false,
        searchField: 'updatedondate',
        sticky: false,
        visible: false,
      }, {
        label: 'Actions',
        field: 'actions',
        sortable: false,
        searchable: false,
        searchField: '',
        sticky: false,
        visible: false,
      },
    ],

    customizations: {
      isBordered: false,
      isCheckable: false,
      isNarrowed: false,
      isStickyHeaders: false,
      isScrollable: true,
      isStriped: true,
    },
  }),

  computed: {
    ...mapGetters({
      grid: 'Grids/getGrid',
      opportunities: 'Opportunities/getInfluencing',
      opportunity: 'Opportunities/getOpportunity',
      opportunityStages: 'Lookups/getOpportunityStages',
      probabilities: 'Lookups/getProbabilities',
      opportunityTypes: 'Lookups/getOpportunityTypes',
      temperatures: 'Lookups/getTemperatures',
      loading: 'Opportunities/getLoading',
    }),

    firstItem() {
      const firstItem = this.page * this.perPage - this.perPage + 1;
      return firstItem >= 0 ? firstItem : 0;
    },

    body() {
      return this.checkedRows.length >= 1 ? this.checkedRows : this.opportunities;
    },

    account: {
      get() {
        return this.$store.state.Opportunities.opportunity.account;
      },

      set(value) {
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_ACCOUNT', value);
      },
    },

    primaryContact: {
      get() {
        return this.$store.state.Opportunities.opportunity.primaryContact;
      },

      set(value) {
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_PRIMARY_CONTACT', value);
      },
    },

    probability: {
      get() {
        return this.$store.state.Opportunities.opportunity.estimatedPotentials.probability;
      },

      set(value) {
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_PROBABILITY', value);
      },
    },

    estimatedRevenue: {
      get() {
        return this.$store.state.Opportunities.opportunity.estimatedPotentials.estimatedRevenue;
      },

      set(value) {
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_ESTIMATED_REVENUE', value);
      },
    },

    estGrossProfitPercentage: {
      get() {
        return this.$store.state.Opportunities.opportunity.estimatedPotentials
          .estGrossProfitPercentage;
      },

      set(value) {
        return this.$store.commit(
          'Opportunities/SET_OPPORTUNITY_EST_GROSS_PROFIT_PERCENTAGE',
          value,
        );
      },
    },

    estimatedGrossProfit: {
      get() {
        return this.$store.state.Opportunities.opportunity.estimatedPotentials.estimatedGrossProfit;
      },

      set(value) {
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_ESTIMATED_GROSS_PROFIT', value);
      },
    },

    temperatureId: {
      get() {
        return this.$store.state.Opportunities.opportunity.temperatureId;
      },

      set(value) {
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_TEMPERATURE_ID', value);
      },
    },

    stageId: {
      get() {
        return this.$store.state.Opportunities.opportunity.stageId;
      },

      set(value) {
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_STAGE_ID', value);
      },
    },

    bidDueDate: {
      get() {
        return this.$store.state.Opportunities.opportunity.estimatedPotentials.bidDueDate;
      },

      set(value) {
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_BID_DUE_DATE', value);
      },
    },

    estBookingDate: {
      get() {
        return this.$store.state.Opportunities.opportunity.estimatedPotentials.estBookingDate;
      },

      set(value) {
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_EST_BOOKING_DATE', value);
      },
    },

    estInstallDate: {
      get() {
        return this.$store.state.Opportunities.opportunity.estimatedPotentials.estInstallDate;
      },

      set(value) {
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_EST_INSTALL_DATE', value);
      },
    },

    estInvoiceDate: {
      get() {
        return this.$store.state.Opportunities.opportunity.estimatedPotentials.estInvoiceDate;
      },

      set(value) {
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_EST_INVOICE_DATE', value);
      },
    },

  },

  watch: {
    perPage: {
      handler() {
        this.fetchOpportunities();
      },
      deep: true,
    },

    selectedAccount(value) {
      if (value.accountId) {
        this.$store.commit('Opportunities/SET_OPPORTUNITY_ACCOUNT_ID', value.accountId);
      }
    },

    selectedContact(value) {
      if (value.contactId) {
        this.$store.commit('Opportunities/SET_OPPORTUNITY_PRIMARY_CONTACT_ID', value.contactId);
      }
    },
  },

  async created() {
    try {
      const organizationId = localStorage.getItem('organization_id');
      const response = await this.$store.dispatch('Grids/fetchGrid', {
        gridName: 'influencing',
        organizationId,
      });
      if (response) {
        this.setGrid();
      } else {
        await this.$store.dispatch('Grids/createGrid', {
          organizationid: localStorage.getItem('organization_id'),
          userid: localStorage.getItem('user_id'),
          columns: this.columns,
          name: 'influencing',
          customizations: this.customizations,
        });
      }
    } catch (error) {
      console.error(error);
    }
  },

  mounted() {
    this.filters = this.$route.query;
    this.fetchOpportunities();
    this.$store.dispatch('Lookups/fetchTemperatures');
    this.$store.dispatch('Lookups/fetchProbabilities');
  },

  destroyed() {
    if (localStorage.getItem('access_token')) {
      this.$store.dispatch('Grids/updateGrid', {
        organizationid: localStorage.getItem('organization_id'),
        userid: localStorage.getItem('user_id'),
        columns: this.columns,
        name: 'influencing',
        customizations: this.customizations,
      });
    }
    this.$store.commit('Opportunities/CLEAR_OPPORTUNITIES');
    this.$store.commit('Opportunities/CLEAR_OPPORTUNITY');
  },

  methods: {
    setGrossProfit() {
      if (this.estimatedRevenue > 0) {
        const estimatedGrossProfit = (this.estimatedRevenue / 100) * this.estGrossProfitPercentage;
        this.$store.commit(
          'Opportunities/SET_OPPORTUNITY_ESTIMATED_GROSS_PROFIT',
          estimatedGrossProfit,
        );
      }
    },

    setGrossProfitPercentage() {
      if (this.estimatedRevenue > 0) {
        const estGrossProfitPerc = (this.estimatedGrossProfit / this.estimatedRevenue) * 100;
        this.$store.commit(
          'Opportunities/SET_OPPORTUNITY_EST_GROSS_PROFIT_PERCENTAGE',
          estGrossProfitPerc,
        );
      }
    },

    setGrid() {
      this.columns.sort(
        (a, b) => this.grid.columns.findIndex(
          (el) => el.label === a.label,
        ) - this.grid.columns.findIndex((el) => el.label === b.label),
      );
      const newColumns = this.columns.map(
        (el, index) => ({ ...el, visible: this.grid.columns[index].visible }),
      );
      this.columns = newColumns;
      this.customizations = this.grid.customizations;
    },
    /**
     * sendMail
     *
     * @param {String} email
     */
    sendMail(email) {
      window.location.assign(`mailto:${email}`);
    },

    /**
     * addActivity
     *
     * @param {Number} opportunityId
     * @param {String} opportunity
     * @param {Number} typeId
     *
     */
    addActivity(opportunityId, opportunity, typeId) {
      this.$router.push(`/activities/add?opportunityId=${opportunityId}&opportunity=${opportunity}&typeId=${typeId}`);
      this.$store.commit('Activities/SET_ACTIVITY_ACTIVITY_TYPE_ID', typeId);
    },

    /**
     * fetchOpportunities
     *
     */
    async fetchOpportunities() {
      let filters;
      if (typeof this.filters !== 'object') {
        filters = '';
      } else {
        filters = { ...this.filters };
        Object.keys(filters).forEach((key) => filters[key] === '' && delete filters[key]);
        if (Object.keys(filters).includes('createdAt')) {
          filters.createdAt = new Date(filters.createdAt).setDate(
            new Date(filters.createdAt).getDate(),
          );
          filters.createdAt = new Date(filters.createdAt).toISOString().substring(0, 10);
        }

        if (Object.keys(filters).includes('updatedon')) {
          filters.updatedon = new Date(filters.updatedon).setDate(
            new Date(filters.updatedon).getDate(),
          );
          filters.updatedon = new Date(filters.updatedon).toISOString().substring(0, 10);
        }
      }

      try {
        const response = await this.$store.dispatch('Opportunities/fetchAccountInfluencing', {
          accountId: this.$route.params.accountId,
          page: this.page - 1,
          perPage: this.perPage,
          sort: [this.sortField, this.sortOrder],
          globalSearch: this.searchClause ? `&globalsearch=${this.searchClause}` : '',
          filters: filters && Object.keys(filters).length > 0
            ? `&${new URLSearchParams(filters).toString()}` : '',
        });
        this.total = response.totalElements;
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * onPageChange
     *
     * @param {number} page
     */
    onPageChange(page) {
      this.page = page;
      this.fetchOpportunities();
    },

    /**
     * onSort
     *
     * @param {String} field
     * @param {String} order
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.fetchOpportunities();
    },

    /**
     * onFilter
     *
     * @param {object} filters
     */
    onFilter(filters) {
      this.filters = filters;
      this.fetchOpportunities();
    },

    /**
     * resetFilters
     *
     */
    resetFilters() {
      this.$refs.opportunitiesTable.resetMultiSorting();
      this.$refs.opportunitiesTable.filters = {};
      this.searchClause = '';
      this.filters = {};
      this.fetchOpportunities();
    },

    /**
     * onEdit
     *
     * @param {object} opportunity
     */
    onEdit(opportunity) {
      this.$store.commit('Opportunities/SET_OPPORTUNITY', opportunity);
      this.selectedOpportunityId = opportunity.id;
      this.isEditing = true;
    },

    /**
     * searchOpportunity
     *
     */
    searchOpportunity(value) {
      this.searchClause = value;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.fetchOpportunities();
      }, 800);
    },

    /**
     * updateOpportunity
     *
     */
    async updateOpportunity() {
      const payload = { ...this.opportunity };
      try {
        this.opportunityStages.forEach((stage) => {
          if (stage.orderSequence === payload.stageId) {
            payload.stageId = stage.id;
          }
        });
        await this.$store.dispatch('Opportunities/updateOpportunity', {
          opportunityId: this.selectedOpportunityId,
          opportunity: payload,
        });
        await this.fetchOpportunities();
        this.selectedOpportunityId = '';
        this.isEditing = false;
        this.$store.commit('Opportunities/CLEAR_OPPORTUNITY');
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="css" scoped></style>
