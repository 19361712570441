<template>
  <!-- contacts form component -->
  <validation-observer
    ref="contactsForm"
    v-slot="{ invalid, handleSubmit }"
  >
    <form
      autocomplete="off"
      @submit.prevent="handleSubmit(updateContact)"
    >
      <!-- page header component -->
      <page-header
        :name="`${contact.firstName} ${contact.lastName}`"
        icon="account"
      >
        <template #actions>
          <div class="level-item">
            <b-field>
              <b-switch
                v-model="isActive"
                type="is-info"
              >
                active
              </b-switch>
            </b-field>
          </div>

          <div class="level-item">
            <b-field>
              <b-button
                :disabled="invalid"
                native-type="submit"
                type="is-success"
                icon-left="content-save"
              >
                update contact
              </b-button>
            </b-field>
          </div>
        </template>
      </page-header>

      <!-- loading component -->
      <b-loading
        v-model="isLoading"
        :is-full-page="true"
        :can-cancel="false"
      />

      <section class="section">
        <div class="container">
          <div class="columns">
            <div class="column is-three-fifths">
              <contacts-form :contact-types="contactTypes" />
            </div>
            <div class="column">
              <div class="card is-primary is-spaced-bottom">
                <header class="card-header">
                  <p class="card-header-title">
                    membership
                  </p>
                </header>
                <div class="card-content">
                  <contacts-membership-form />
                  <div
                    v-for="(account, index) in accounts"
                    :key="index"
                    class="media"
                  >
                    <div class="media-content">
                      <div class="content">
                        {{ account.name }}
                        <br>
                        <small>
                          phone: {{ account.phone }}
                        </small>
                      </div>
                    </div>

                    <div class="media-right">
                      <div class="content">
                        <b-button
                          icon-right="delete"
                          type="is-text"
                          @click="deleteContactAccount(account.id)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <notes-card-form
                :notes="notes"
                @submit-note="createNote"
                @delete-note="deleteNote"
              />
            </div>
          </div>

          <b-field>
            <b-button
              :disabled="invalid"
              native-type="submit"
              type="is-success"
              icon-left="content-save"
              value="update contact"
            >
              update contact
            </b-button>
          </b-field>
        </div>
      </section>
    </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { mapGetters } from 'vuex';

import { PageHeader } from '@/components/Shared';
import { ContactsForm, ContactsMembershipForm } from '@/components/Contacts';
import { NotesCardForm } from '@/components/Activities';
import ContactsMixin from '@/mixins/ContactsMixin';

export default {
  name: 'EditContact',

  components: {
    ValidationObserver,
    PageHeader,
    ContactsForm,
    ContactsMembershipForm,
    NotesCardForm,
  },

  filters: {
    date(value) {
      return new Date(value).toLocaleDateString();
    },
  },

  mixins: [ContactsMixin],

  data: () => ({
    isLoading: true,
    isInvalid: false,
    message: '',
  }),

  computed: {
    ...mapGetters({
      accounts: 'Accounts/getAccounts',
      activity: 'Activities/getActivity',
      notes: 'Activities/getNotes',
      contact: 'Contacts/getContact',
      contactTypes: 'Lookups/getContactTypes',
      activityTypes: 'Lookups/getActivityTypes',
    }),

    isActive: {
      get() {
        return this.$store.state.Contacts.contact.isActive;
      },

      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_IS_ACTIVE', value);
      },
    },
  },

  async created() {
    this.$store.commit('Contacts/CLEAR_CONTACT');
    try {
      await this.$store.dispatch('Settings/fetchCustomFields', 'Contact');
      await this.$store.dispatch('Contacts/fetchContact', this.$route.params.contactId);
      await this.mapValues();
      await this.mapFields();
      await this.$store.dispatch('Accounts/fetchContactAccounts', {
        page: 0,
        perPage: 5,
        contactId: this.$route.params.contactId,
        sort: ['createdAt', 'desc'],
        globalSearch: '',
        filters: '',
      });
      await this.$store.dispatch('Activities/fetchContactActivities', {
        page: 0,
        perPage: 5,
        contactId: this.$route.params.contactId,
        sort: ['createdAt', 'desc'],
        globalSearch: '',
        filters: '',
      });
      await this.$store.dispatch('Lookups/fetchActivityTypes');
      await this.$store.dispatch('Lookups/fetchContactTypes');
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },

  destroyed() {
    this.$store.commit('Activities/CLEAR_ACTIVITY');
    this.isInvalid = '';
    this.message = '';
  },

  methods: {
    async updateContact() {
      this.isLoading = true;
      try {
        const payload = { ...this.contact };
        if (payload.contactPhoto) {
          payload.contactPhoto = payload.contactPhoto.startsWith('http')
            ? null : payload.contactPhoto;
        }

        if (payload.title === null) {
          payload.title = '';
        }

        if (payload.prefix === null) {
          payload.prefix = '';
        }

        if (payload.photo) {
          delete payload.photo;
        }
        payload.dob = this.month ? `${this.month}-${this.date}` : '';
        await this.$store.dispatch('Contacts/updateContact', payload);
        await this.$router.go('-1');
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    async createNote() {
      this.$store.commit('Activities/SET_ACTIVITY_CONTACT_ID', this.$route.params.contactId);
      this.isLoading = true;
      const activityTypes = [...this.activityTypes];
      activityTypes.forEach((activityType) => {
        if (activityType.value === 'Note') {
          this.$store.commit('Activities/SET_ACTIVITY_ACTIVITY_TYPE_ID', activityType.id);
        }
      });
      const payload = { ...this.activity };
      try {
        if (payload.file.fileName === '') {
          payload.file = null;
        }

        payload.reminder = null;
        await this.$store.dispatch('Activities/createActivity', payload);
        this.$store.commit('Activities/CLEAR_ACTIVITY');
        await this.$store.dispatch('Activities/fetchContactActivities', {
          contactId: this.$route.params.contactId,
          page: 0,
          perPage: 5,
          sort: ['createdAt', 'desc'],
          globalSearch: '',
          filters: '',
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    async deleteNote(noteId) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('Activities/deleteActivity', noteId);
        await this.$store.dispatch('Activities/fetchContactActivities', {
          contactId: this.$route.params.contactId,
          page: 0,
          perPage: 5,
          sort: ['createdAt', 'desc'],
          globalSearch: '',
          filters: '',
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    async deleteContactAccount(accountId) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('Contacts/deleteContactAccount', {
          contactId: this.$route.params.contactId,
          accountId,
        });
        await this.$store.dispatch('Accounts/fetchContactAccounts', {
          contactId: this.$route.params.contactId,
          page: 0,
          perPage: 5,
          sort: ['createdAt', 'desc'],
          globalSearch: '',
          filters: '',
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>

</style>
