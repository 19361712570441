<template>
  <div>
    <!-- loading component -->
    <b-loading
      v-model="loading"
      :is-full-page="false"
    />

    <div class="level">
      <div class="level-left">
        <div class="level-item" />
      </div>

      <div class="level-right">
        <div class="level-item">
          <b-checkbox-button
            v-model="isFiltering"
            size="is-small"
          >
            <b-icon icon="filter" />
            <span>Filter</span>
          </b-checkbox-button>
        </div>

        <div
          v-if="isFiltering"
          class="level-item"
        >
          <b-dropdown
            v-model="bookingOption"
            aria-role="list"
            position="is-bottom-left"
          >
            <b-button
              slot="trigger"
              size="is-small"
              type="is-info"
              icon="chevron-down"
            >
              Booking: {{ bookingOption.name }}
            </b-button>

            <template v-for="(option, index) in bookingOptions">
              <b-dropdown-item
                :key="index"
                :value="option"
              >
                {{ option.name }}
              </b-dropdown-item>
            </template>
          </b-dropdown>

          <b-dropdown
            v-model="month"
            aria-role="list"
            position="is-bottom-left"
          >
            <b-button
              slot="trigger"
              size="is-small"
              type="is-info"
              icon="chevron-down"
            >
              {{ month.name }}
            </b-button>

            <template v-for="(month, index) in months">
              <b-dropdown-item
                :key="index"
                :value="month"
              >
                {{ month.name }}
              </b-dropdown-item>
            </template>
          </b-dropdown>
        </div>
      </div>
    </div>

    <div class="columns is-mobile is-gapless">
      <div
        v-for="(stage, index) in stages"
        :key="index"
        class="column stages"
      >
        <div
          v-if="!isCollapsed(stage.id)"
          class="stage-item is-primary"
        >
          <span class="heading has-text-white has-text-weight-bold stage-title">
            {{ index++ }}.
          </span>
          <span
            class="heading has-text-white has-text-weight-bold stage-title"
          >
            {{ stage.value }}
          </span>
          <span class="stage-count is-size-7">
            {{ getStageCount(stage.id) }}
          </span>
        </div>

        <div
          v-if="isCollapsed(stage.id)"
          class="stage-item is-collapsed is-primary"
        >
          <span class="heading has-text-white has-text-weight-bold stage-title">
            {{ index++ }}.
          </span>
          <span class="stage-count is-size-7">
            {{ getStageCount(stage.id) }}
          </span>
        </div>

        <div class="has-text-centered">
          <span
            class="tag is-pointer"
            @click="isCollapsed(stage.id) ? expand(stage.id) : collapse(stage.id)"
          >
            {{ isCollapsed(stage.id) ? 'expand' : 'collapse' }}
          </span>
        </div>
      </div>
    </div>

    <div class="columns is-mobile is-v-scrollable is-hoverable has-bordered-columns is-spaced-top">
      <div
        v-for="(stage, index) in stages"
        :key="index"
        class="column"
      >
        <div v-if="!isCollapsed(stage.id)">
          <h5 class="has-text-primary subtitle has-text-centered">
            {{ getStageEstimatedRevenue(stage.id) | currency }}
          </h5>
          <template
            v-for="(opportunity, idx) in filteredOpportunities"
          >
            <div
              v-if="opportunity.stage.value === stage.value"
              :key="idx"
            >
              <div
                class="box is-bordered"
                @click="$router.push(`/opportunities/${opportunity.id}/view`)"
              >
                <div class="content">
                  <p>
                    <strong class="has-text-primary is-pointer">
                      {{ opportunity.name }}
                    </strong><br>
                    <span>{{ opportunity.estimatedPotentials.estimatedRevenue | currency }}</span>
                    <br>
                    <span
                      v-for="(owner, ownerIndex) in opportunity.owners"
                      :key="ownerIndex"
                      class="has-text-primary"
                    >
                      {{ `${owner.owner.firstName} ${owner.owner.lastName}` }}
                    </span><br>
                  </p>
                  <p :class="opportunity.isOverdue ? 'has-text-danger' : 'has-text-grey'">
                    <span class="has-text-weight-semibold"> Booking </span>:
                    <template v-if="opportunity.estimatedPotentials.estBookingDate">
                      {{ opportunity.estimatedPotentials.estBookingDate | date }}
                    </template>
                    <b-icon
                      v-if="opportunity.isOverdue"
                      icon="close"
                    />
                  </p>
                  <p class="has-text-grey">
                    <span class="has-text-weight-semibold">Install</span>:
                    {{ opportunity.estimatedPotentials.estInstallDate | date }}
                  </p>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <b-pagination
      v-model="currentPage"
      :total="opportunitiesTotal"
      :per-page="perPage"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      @change="onPageChange"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {

  name: 'OpportunitiesStages',

  filters: {
    date(value) {
      return new Date(value).toLocaleDateString();
    },
  },

  data: () => ({
    currentPage: 1,
    perPage: 50,
    opportunitiesTotal: 0,
    months: [
      { id: 0, name: 'January' },
      { id: 1, name: 'February' },
      { id: 2, name: 'March' },
      { id: 3, name: 'April' },
      { id: 4, name: 'May' },
      { id: 5, name: 'June' },
      { id: 6, name: 'July' },
      { id: 7, name: 'August' },
      { id: 8, name: 'September' },
      { id: 9, name: 'October' },
      { id: 10, name: 'November' },
      { id: 11, name: 'December' },
    ],
    month: { id: 0, name: 'January' },
    bookingOptions: [
      { id: '-30', name: 'Within 30 Days' },
      { id: '-7', name: 'Within 7 Days' },
      { id: '1', name: 'Overdue' },
    ],
    bookingOption: { id: '-30', name: 'Within 30 Days' },
    isFiltering: false,
    collapsedStages: [],
  }),

  computed: {
    ...mapGetters({
      loading: 'Opportunities/getLoading',
      opportunities: 'Opportunities/getStageOpportunities',
      stages: 'Lookups/getOpportunityStages',
    }),

    filteredOpportunities() {
      const opportunities = [...this.opportunities];
      if (this.isFiltering) {
        const oneDay = 24 * 60 * 60 * 1000;
        const filteredOpportunities = opportunities.filter(
          (opportunity) => (new Date(opportunity.createdAt).getMonth() === this.month.id),
        );

        const mappedOpportunties = filteredOpportunities.map((opportunity) => {
          if (Math.round(
            (new Date(
              opportunity.estimatedPotentials.estBookingDate,
            ).getTime() - new Date().getTime()
            ) / (oneDay),
          ) > this.bookingOption.id) {
            return { isOverdue: true, ...opportunity };
          }
          return opportunity;
        });
        return mappedOpportunties;
      }

      return opportunities;
    },
  },

  watch: {
    month(value) {
      this.opportunities = this.opportunities.filter(
        (opportunity) => new Date(opportunity.createdAt).getMonth() === value.id,
      );
    },
  },

  async mounted() {
    // TODO: Enable on dev server
    // await this.fetchOpportunities();
    await this.$store.dispatch('Lookups/fetchOpportunityStages');
  },

  methods: {
    async fetchOpportunities() {
      try {
        const response = await this.$store.dispatch('Opportunities/fetchStageOpportunities', {
          page: this.currentPage - 1,
          perPage: this.perPage,
          sort: ['createdAt', 'desc'],
          globalSearch: '',
          filters: '',
        });
        this.opportunitiesTotal = response.totalElements;
      } catch (error) {
        console.error(error);
      }
    },

    onPageChange(page) {
      this.currentPage = page;
      this.fetchOpportunities();
    },

    getStageCount(stageId) {
      const data = [...this.filteredOpportunities];
      const opportunities = data.filter(
        (opportunity) => opportunity.stage.id === stageId,
      );
      return opportunities.length;
    },

    getStageEstimatedRevenue(stageId) {
      const data = [...this.filteredOpportunities];
      const opportunities = data.filter(
        (opportunity) => opportunity.stage.id === stageId,
      );
      return opportunities.reduce(
        (sum, opportunity) => sum + opportunity.estimatedPotentials.estimatedRevenue, 0,
      );
    },

    collapse(stageId) {
      this.collapsedStages.push(stageId);
    },

    isCollapsed(stageId) {
      const index = this.collapsedStages.find((stage) => stage === stageId);
      return !!index;
    },

    expand(stageId) {
      const index = this.collapsedStages.findIndex((stage) => stage === stageId);
      this.collapsedStages.splice(index, 1);
    },
  },
};
</script>

<style scoped>
.columns.has-bordered-columns > .column:nth-child(-n+6) {
  border-right: 1px solid #ccc;
}

.is-bordered {
  border: 1px solid #ccc;
}

.columns.is-hoverable:hover > .column {
  opacity: 0.25;
}

.columns.is-hoverable:hover > .column:hover {
  opacity: 1;
}

.columns.is-hoverable:hover > .column:hover > * {
  opacity: 1;
}

.is-v-scrollable {
  max-height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
