<template>
  <fieldset>
    <validation-provider
      v-slot="{ errors }"
      rules="required"
      name="name"
    >
      <b-field
        horizontal
        label="Opportunity Name"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
        class="is-required"
      >
        <b-input v-model="name" />
      </b-field>
    </validation-provider>

    <validation-provider
      v-slot="{ errors }"
      rules="required"
      name="description"
    >
      <b-field
        horizontal
        label="Description"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
        class="is-required"
      >
        <b-input
          v-model="description"
          type="textarea"
        />
      </b-field>
    </validation-provider>

    <b-field
      horizontal
      label="Type"
      label-for="opportunityId"
    >
      <div class="columns">
        <div class="column is-half">
          <b-taginput
            v-model="typeId"
            :data="filteredTags"
            autocomplete
            field="value"
            type="is-info"
            :open-on-focus="true"
            placeholder="Add a type"
            @typing="getFilteredTags"
          />
        </div>
      </div>
    </b-field>

    <validation-provider
      v-slot="{ errors }"
      rules="required"
      name="stage"
    >
      <b-field
        horizontal
        label="Stage"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
        class="is-required"
      >
        <div class="columns">
          <div class="column">
            <b-slider
              v-model="stageId"
              :min="minStage"
              :max="maxStage"
              :tooltip="true"
            >
              <div
                v-for="(stage, index) in opportunityStages"
                :key="index"
              >
                <b-slider-tick
                  :key="index"
                  :value="stage.orderSequence"
                >
                  {{ stage.value }}
                </b-slider-tick>
              </div>
            </b-slider>
          </div>
        </div>
      </b-field>
    </validation-provider>

    <validation-provider
      v-if="opportunityReasonStages.length > 0 && hasReasons"
      v-slot="{ errors }"
      ref="closingReasonField"
      :rules="{'required': required}"
      name="closing reason"
    >
      <b-field
        horizontal
        label="Closing reason"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
        :class="{'is-required': required}"
      >
        <div class="columns">
          <div class="column is-half">
            <b-select
              v-model="closingReason"
              expanded
              :loading="isloadingReasons"
            >
              <option
                v-for="(reason, index) in opportunityClosingReasons"
                :key="index"
                :value="reason.id"
              >
                {{ reason.reasonValue }}
              </option>
            </b-select>
          </div>
        </div>
      </b-field>
    </validation-provider>

    <br>

    <validation-provider name="temperature">
      <b-field
        horizontal
        label="Temperature"
      >
        <div class="columns is-mobile">
          <div
            v-for="(temperature, index) in temperatures"
            :key="index"
            class="column is-narrow"
          >
            <b-tooltip
              type="is-info"
              :label="temperature.value"
              position="is-bottom"
            >
              <b-radio-button
                v-model="temperatureId"
                type="is-info"
                :native-value="temperature.id"
                outlined
              >
                <b-icon
                  v-if="temperature.value === 'Cold'"
                  icon="snowflake"
                  class="has-text-grey"
                />
                <b-icon
                  v-if="temperature.value === 'Hot'"
                  icon="fire"
                  class="has-text-grey"
                />
                <b-icon
                  v-if="temperature.value === 'Warm'"
                  icon="water"
                  class="has-text-grey"
                />
              </b-radio-button>
            </b-tooltip>
          </div>

          <div
            v-if="temperatureId"
            class="column"
          >
            <b-button
              type="is-danger"
              icon-right="close"
              @click="clearTemperatureId"
            />
          </div>
        </div>
      </b-field>
      <br>
    </validation-provider>

    <validation-provider
      v-slot="{ errors }"
      rules="required"
      name="owner"
    >
      <b-field
        horizontal
        label="Owner"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
        class="is-required"
      >
        <div class="columns">
          <div class="column is-half">
            <b-autocomplete
              v-model="owner"
              icon-right="search"
              expanded
              field="fullName"
              :data="ownerSearchResults"
              :loading="isLoading"
              @typing="getOwnersAsyncData"
              @select="(option) => (selectedOwner = option)"
            >
              <template slot-scope="props">
                <div class="media">
                  <div class="media-content">
                    {{ props.option.firstName }}
                    {{ props.option.lastName }}
                    <br>
                    <small>
                      email: {{ props.option.email }} <br>
                      Phone: {{ props.option.phoneCell }}
                    </small>
                  </div>
                </div>
              </template>

              <template #empty>
                No results for {{ owner }}
              </template>
            </b-autocomplete>
          </div>
        </div>
      </b-field>
    </validation-provider>

    <validation-provider
      v-slot="{ errors }"
      rules=""
      name="co-owner"
    >
      <b-field
        horizontal
        label="CO-Owner"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
      >
        <div class="columns">
          <div class="column is-half">
            <b-autocomplete
              v-model="coOwner"
              icon-right="search"
              expanded
              field="fullName"
              :data="coOwnerSearchResults"
              :loading="isLoading"
              @typing="getCoOwnersAsyncData"
              @select="(option) => (selectedCoOwner = option)"
            >
              <template slot-scope="props">
                <div class="media">
                  <div class="media-content">
                    {{ props.option.firstName }}
                    {{ props.option.lastName }}
                    <br>
                    <small>
                      email: {{ props.option.email }} <br>
                      Phone: {{ props.option.phoneCell }}
                    </small>
                  </div>
                </div>
              </template>

              <template #empty>
                No results for {{ coOwner }}
              </template>
            </b-autocomplete>
          </div>

          <div class="column is-narrow">
            <div
              v-if="coOwner"
              @click="clearCoOwner"
            >
              <b-button
                type="is-danger"
                icon-right="close"
              />
            </div>
          </div>
        </div>
      </b-field>
    </validation-provider>

    <b-field
      horizontal
      label="% Split"
    >
      <div class="columns">
        <div class="column is-two-thirds">
          <div class="columns is-mobile is-vcentered">
            <div class="column">
              <validation-provider
                v-slot="{ errors }"
                rules="integer|max_value:100"
                name="owner percentage"
              >
                <b-field
                  label="Owner"
                  label-position="inside"
                  :type="{ 'is-danger': errors[0] }"
                  :message="errors"
                >
                  <b-input
                    v-model="ownerPercentage"
                    min="0"
                    max="100"
                    type="number"
                    :disabled="!coOwnerId"
                  />
                </b-field>
              </validation-provider>
            </div>

            <div class="column">
              <b-field>
                <b-slider
                  v-model="percentage"
                  :min="0"
                  :max="100"
                  type="is-info"
                  :disabled="!coOwnerId"
                />
              </b-field>
            </div>

            <div class="column">
              <validation-provider
                v-slot="{ errors }"
                rules="integer|max_value:100"
                name="co owner percentage"
              >
                <b-field
                  label="CO-Owner"
                  label-position="inside"
                  :type="{ 'is-danger': errors[0] }"
                  :message="errors"
                >
                  <b-input
                    v-model="coOwnerPercentage"
                    min="0"
                    max="100"
                    type="number"
                    :disabled="!coOwnerId"
                  />
                </b-field>
              </validation-provider>
            </div>
          </div>
        </div>
      </div>
    </b-field>

    <validation-provider
      v-slot="{ errors }"
      rules="required"
      name="account"
    >
      <b-field
        horizontal
        label="Account"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
        class="is-required"
      >
        <div class="columns">
          <div class="column is-half">
            <b-autocomplete
              v-model="account"
              class="autoCompleteLink"
              expanded
              field="name"
              icon-right="search"
              :data="accountsSearchResults"
              :loading="isLoading"
              @typing="getAccountsAsyncData"
              @select="(option) => (selectedAccount = option)"
            >
              <template slot-scope="props">
                <div class="media">
                  <div class="media-content">
                    {{ props.option.name }}
                    <br>
                    <small>
                      customer number: {{ props.option.externalId }}
                    </small>
                  </div>
                </div>
              </template>

              <template #empty>
                No results for {{ account }}
                <a
                  href="#"
                  class="link"
                  @click="openModal('newAccountFormModal')"
                >
                  Create new account instead
                </a>
              </template>
            </b-autocomplete>
          </div>
        </div>
      </b-field>
    </validation-provider>

    <validation-provider
      v-slot="{ errors }"
      rules="required"
      name="primaryContact"
    >
      <b-field
        v-if="account"
        horizontal
        label="Primary Contact"
        :message="errors"
        class="is-required"
      >
        <div class="columns">
          <div class="column is-half">
            <b-autocomplete
              v-model="primaryContact"
              class="autoCompleteLink"
              :data="contactsSearchResults"
              field="contactName"
              :loading="isLoadingContacts"
              :open-on-focus="true"
              @select="(option) => (selectedContact = option)"
              @typing="getContactsAsyncData"
            >
              <template slot-scope="props">
                <div class="media">
                  <div class="media-left">
                    <img
                      width="32"
                      :src="props.option.photo ? props.option.photo : defaultImage"
                      :alt="props.option.contactName"
                    >
                  </div>
                  <div class="media-content">
                    {{ props.option.contactName.trim() }}
                    <br>
                    <small>
                      Phone: {{ props.option.phone }}, Email: <b>{{ props.option.email }}</b>
                    </small>
                  </div>
                </div>
              </template>

              <template #empty>
                <template v-if="isLoadingContacts">
                  Loading contacts...
                </template>
                <template v-else>
                  No results for {{ primaryContact }}
                  <a
                    href="#"
                    class="link"
                    @click="openModal('newContactFormModal')"
                  >
                    Create new contact instead
                  </a>
                </template>
              </template>
            </b-autocomplete>
            <a
              href="#"
              class="link"
              @click="openModal('newContactFormModal')"
            >
              Create New Contact
            </a>
          </div>

          <div
            v-if="primaryContact"
            class="column"
          >
            <b-button
              aria-label="Clear Primary Contact"
              type="is-danger"
              icon-right="close"
              @click="clearPrimaryContact"
            />
          </div>
        </div>
      </b-field>
    </validation-provider>

    <b-field
      horizontal
      label="Competitor Info"
    >
      <b-input
        v-model="competitorInfo"
        type="textarea"
      />
    </b-field>

    <b-field horizontal>
      <b-button
        type="is-info"
        @click="isInfluencersTableActive = !isInfluencersTableActive"
      >
        manage influencers
      </b-button>
    </b-field>

    <b-field horizontal>
      <opportunities-influencers-table v-if="isInfluencersTableActive" />
    </b-field>

    <div
      v-if="customFieldsList.length > 0"
      class="customFieldsList"
    >
      <div class="content">
        <h3 class="subtitle has-text-primary is-capitalized">
          Custom Fields
        </h3>
      </div>
      <hr class="divider">

      <validation-provider
        v-for="(field, index) in customFieldsList"
        :key="index"
        v-slot="{ errors }"
        :rules="{required: field.isRequired}"
        :name="field.label"
      >
        <b-field
          v-if="field.type === 'TEXT_INPUT'"
          horizontal
          :label="field.label"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          :class="{'is-required' : field.isRequired }"
        >
          <div class="columns">
            <div
              class="column is-half"
            >
              <b-input v-model="field.value" />
            </div>
          </div>
        </b-field>
        <b-field
          v-else-if="field.type === 'CURRENCY'"
          horizontal
          :label="field.label"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          :class="{'is-required' : field.isRequired }"
        >
          <div class="columns">
            <div class="column">
              <currency-input
                v-model="field.value"
                class="input"
              />
            </div>
          </div>
        </b-field>
        <b-field
          v-else-if="field.type === 'DROP_DOWN'"
          horizontal
          :label="field.label"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          :class="{'is-required' : field.isRequired }"
        >
          <div class="columns">
            <div class="column is-half">
              <b-select
                v-model="field.value"
                expanded
              >
                <option
                  v-for="(option, optionIndex) in field.lookups"
                  :key="optionIndex"
                  :value="option.value"
                >
                  {{ option.value }}
                </option>
              </b-select>
            </div>
            <div class="column">
              <b-button
                aria-label="Clear Value"
                type="is-danger"
                icon-right="close"
                @click="clearValue(index)"
              />
            </div>
          </div>
        </b-field>
        <b-field
          v-else-if="field.type === 'MULTI_SELECT'"
          horizontal
          :label="field.label"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          :class="{'is-required' : field.isRequired }"
        >
          <div class="columns">
            <div class="column is-half">
              <b-taginput
                v-model="field.value"
                :data="field.lookups.map(i => i.value)"
                autocomplete
                field="value"
                type="is-info"
                :open-on-focus="true"
                placeholder="Select one or multiple"
                @typing="(val) => getCustomFieldFilteredTags(val, field)"
              />
            </div>
          </div>
        </b-field>
      </validation-provider>
    </div>

    <div class="content">
      <h3 class="subtitle has-text-primary is-capitalized">
        Project ID
      </h3>
    </div>

    <hr class="divider">

    <b-field
      horizontal
      label="Project Title"
    >
      <div class="columns">
        <div class="column is-narrow">
          <!-- project folders form field -->
          <b-autocomplete
            v-model="projectFolderTitle"
            expanded
            icon-right="search"
            :data="projectFoldersSearchResults"
            :loading="isLoading"
            @typing="getProjectFoldersAsyncData"
            @select="(option) => (selectedProjectFolder = option)"
          >
            <template slot-scope="props">
              <div class="media">
                <div class="media-contet">
                  <strong>Title</strong>: {{ props.option.projectTitle }} <br>
                  <strong>ID</strong>: {{ props.option.projectId }}
                </div>
              </div>
            </template>

            <template #empty>
              No results for {{ projectFolderTitle }}
            </template>
          </b-autocomplete>
        </div>
      </div>
    </b-field>

    <b-field
      v-for="(opportunityProjectFolder, index) in opportunityProjectFolders"
      :key="index"
      horizontal
    >
      <div class="columns">
        <div class="column is-narrow">
          <b-input
            :value="
              `${opportunityProjectFolder.projectTitle} - ${opportunityProjectFolder.projectId}`
            "
            disabled
          />
        </div>

        <div class="column">
          <b-button
            type="is-danger"
            icon-right="close"
            @click="removeProjectFolder(opportunityProjectFolder)"
          />
        </div>
      </div>
    </b-field>

    <div class="content">
      <h3 class="subtitle has-text-primary is-capitalized">
        Estimated Potential and Milestones
      </h3>
    </div>

    <hr class="divider">

    <opportunities-estimated-potentials-form :probabilities="probabilities" />

    <br>

    <b-field
      horizontal
      label="Add Tags"
    >
      <b-taginput
        v-model="tags"
        field="tag"
        ellipsis
        icon="tag"
      />
    </b-field>

    <br>

    <div
      v-if="$route.name === 'EditOpportunity'"
      class="content"
    >
      <h3 class="subtitle has-text-primary is-capitalized">
        Leads Details
      </h3>
    </div>

    <hr
      v-if="$route.name === 'EditOpportunity'"
      class="divider"
    >

    <validation-provider
      v-if="$route.name === 'EditOpportunity'"
      v-slot="{ errors }"
      rules=""
      name="lead source"
    >
      <b-field
        horizontal
        label="Lead Source"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
      >
        <b-select
          v-model="leadsSourceId"
          :disabled="true"
        >
          <option
            v-for="(leadSource, index) in leadSources"
            :key="index"
            :value="leadSource.id"
          >
            {{ leadSource.value }}
          </option>
        </b-select>
      </b-field>
    </validation-provider>

    <b-modal
      v-model="isComponentModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="create data form modal"
      aria-modal
    >
      <template #default="props">
        <accounts-form-modal
          v-if="isFormModalAccount"
          module-name="opportunities"
          @close="props.close"
        />
        <contacts-form-modal
          v-if="isFormModalContact"
          module-name="opportunities"
          :selected-account="selectedAccount"
          @close="props.close"
          @set-contact="setPrimaryContact"
        />
      </template>
    </b-modal>
  </fieldset>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { mapGetters, mapActions } from 'vuex';

import defaultImage from '@/assets/images/avatar.svg';

import { ContactsFormModal } from '@/components/Contacts';
import debounce from '@/utils/debounce';
import OpportunitiesEstimatedPotentialsForm from './OpportunitiesEstimatedPotentialsForm.vue';
import OpportunitiesInfluencersTable from './OpportunitiesInfluencersTable.vue';
import AccountsFormModal from '../Shared/AccountsFormModal.vue';

export default {
  name: 'OpportunitiesForm',

  components: {
    ValidationProvider,
    OpportunitiesEstimatedPotentialsForm,
    OpportunitiesInfluencersTable,
    AccountsFormModal,
    ContactsFormModal,
  },

  filters: {
    date(value) {
      return new Date(value).toLocaleDateString();
    },
  },

  props: {
    opportunityStages: {
      type: Array,
      required: true,
    },

    temperatures: {
      type: Array,
      required: true,
    },

    opportunityTypes: {
      type: Array,
      required: true,
    },

    probabilities: {
      type: Array,
      required: true,
    },

  },

  data: () => ({
    isLoading: false,
    isLoadingContacts: false,
    isloadingReasons: false,
    isInfluencersTableActive: false,
    defaultImage,
    selectedAccount: null,
    accountsSearchResults: [],
    selectedContact: null,
    contactsSearchResults: [],
    selectedOwner: null,
    ownerSearchResults: [],
    selectedCoOwner: null,
    coOwnerSearchResults: [],
    projectFolderTitle: '',
    projectFoldersSearchResults: [],
    selectedProjectFolder: null,
    modalName: null,
    isComponentModalActive: false,
    timer: null,
  }),

  computed: {
    ...mapGetters({
      opportunityProjectFolders: 'Opportunities/getOpportunityProjectFolders',
      opportunityReasonStages: 'Opportunities/getOpportunityReasonStages',
      opportunityClosingReasons: 'Opportunities/getOpportunityStageReasons',
      leadStatuses: 'Lookups/getLeadStatuses',
      leadSources: 'Lookups/getLeadSources',
    }),

    filteredTags() {
      return [...this.opportunityTypes];
    },

    typeValue() {
      const newArr = this.typeId.map((element) => {
        const arr = this.opportunityTypes.filter((i) => i.id === element);
        return element === arr[0].id ? arr[0].value : '';
      });
      return newArr;
    },

    isFormModalAccount() {
      return this.modalName === 'newAccountFormModal';
    },
    isFormModalContact() {
      return this.modalName === 'newContactFormModal';
    },

    maxStage() {
      const data = [...this.opportunityStages];
      const orderSequence = data.map((el) => el.orderSequence).sort();
      return Math.max(...orderSequence);
    },

    minStage() {
      const data = [...this.opportunityStages];
      const orderSequence = data.map((el) => el.orderSequence).sort();
      return Math.min(...orderSequence);
    },

    required() {
      if (!this.opportunityStages || !this.stageId) return false;

      const matchingStages = this.opportunityStages.filter((i) => i.orderSequence === this.stageId);
      if (matchingStages.length === 0) return false;

      const { code } = matchingStages[0];

      if (code && code.includes('closed') && this.opportunityReasonStages.length > 0) {
        const result = this.opportunityReasonStages.filter((i) => i.stageCode === code);
        return result.length > 0 ? result[0].isRequired : false;
      }
      return false;
    },

    hasReasons() {
      if (!this.opportunityStages || !this.stageId) return false;

      const matchingStages = this.opportunityStages.filter((i) => i.orderSequence === this.stageId);
      if (matchingStages.length === 0) return false;

      const { code } = matchingStages[0];

      if (code && code.includes('closed') && this.opportunityReasonStages.length > 0) {
        const result = this.opportunityReasonStages.filter((i) => i.stageCode === code);
        return result.length > 0;
      }
      return false;
    },

    percentage: {
      get() {
        return this.$store.state.Opportunities.opportunity.owner.percentage;
      },

      set(value) {
        this.$store.commit('Opportunities/SET_OPPORTUNITY_CO_OWNER_PERCENTAGE', 100 - value);
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_OWNER_PERCENTAGE', value);
      },
    },

    name: {
      get() {
        return this.$store.state.Opportunities.opportunity.name;
      },

      set(value) {
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_NAME', value);
      },
    },

    description: {
      get() {
        return this.$store.state.Opportunities.opportunity.description;
      },

      set(value) {
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_DESCRIPTION', value);
      },
    },

    typeId: {
      get() {
        const newArr = this.$store.state.Opportunities.opportunity.typeId;
        return this.opportunityTypes.filter((i) => {
          if (newArr) {
            const arr = newArr.filter((o) => o === i.id);
            if (arr.length > 0) {
              return i.id === arr[0];
            }
            return false;
          }
          return false;
        });
      },

      set(value) {
        const newArr = value.map((i) => i.id);
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_TYPE_ID', newArr);
      },
    },

    temperatureId: {
      get() {
        return this.$store.state.Opportunities.opportunity.temperatureId;
      },

      set(value) {
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_TEMPERATURE_ID', value);
      },
    },

    stageId: {
      get() {
        return parseInt(this.$store.state.Opportunities.opportunity.stageId, 10);
      },

      set(value) {
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_STAGE_ID', parseInt(value, 10));
      },
    },

    owner: {
      get() {
        return this.$store.state.Opportunities.opportunity.owner.owner;
      },

      set(value) {
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_OWNER', value);
      },
    },

    ownerPercentage: {
      get() {
        return this.$store.state.Opportunities.opportunity.owner.percentage;
      },

      set(value) {
        this.$store.commit('Opportunities/SET_OPPORTUNITY_CO_OWNER_PERCENTAGE', 100 - value);
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_OWNER_PERCENTAGE', value);
      },
    },

    coOwnerId: {
      get() {
        return this.$store.state.Opportunities.opportunity.coOwner.id;
      },
    },

    coOwner: {
      get() {
        return this.$store.state.Opportunities.opportunity.coOwner.owner;
      },

      set(value) {
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_CO_OWNER', value);
      },
    },

    coOwnerPercentage: {
      get() {
        return this.$store.state.Opportunities.opportunity.coOwner.percentage;
      },

      set(value) {
        this.$store.commit('Opportunities/SET_OPPORTUNITY_OWNER_PERCENTAGE', 100 - value);
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_CO_OWNER_PERCENTAGE', value);
      },
    },

    account: {
      get() {
        return this.$store.state.Opportunities.opportunity.account;
      },

      set(value) {
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_ACCOUNT', value);
      },
    },

    accountId: {
      get() {
        return this.$store.state.Opportunities.opportunity.accountId;
      },
    },

    primaryContact: {
      get() {
        return this.$store.state.Opportunities.opportunity.primaryContact;
      },

      set(value) {
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_PRIMARY_CONTACT', value);
      },
    },

    competitorInfo: {
      get() {
        return this.$store.state.Opportunities.opportunity.competitorInfo;
      },

      set(value) {
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_COMPETITOR_INFO', value);
      },
    },

    closingReason: {
      get() {
        return this.$store.state.Opportunities.opportunity.reasonId;
      },

      set(value) {
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_CLOSING_REASON', value);
      },
    },

    tags: {
      get() {
        return this.$store.state.Opportunities.opportunity.tags;
      },

      set(value) {
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_TAGS', value);
      },
    },

    customFieldsList: {
      get() {
        return this.$store.state.Settings.customFields.leadOppCustomFields;
      },

      set(value) {
        return this.$store.commit('Settings/SET_LEAD_OPP_CUSTOM_FIELDS', value);
      },
    },

    customFields: {
      get() {
        return this.$store.state.Opportunities.opportunity.customFields;
      },

      set(value) {
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_CUSTOM_FIELDS', value);
      },
    },

    opportunity: {
      get() {
        return this.$store.state.Opportunities.opportunity;
      },

      // set(value) {
      //   return this.$store.commit('Opportunities/SET_OPPORTUNITY_CUSTOM_FIELDS', value);
      // },
    },

    leadStageId: {
      get() {
        return this.$store.state.Opportunities.opportunity.leadStageId;
      },

      set(value) {
        return this.$store.commit('Opportunities/SET_LEAD_STAGE_ID', value);
      },
    },

    leadsSourceId: {
      get() {
        return this.$store.state.Opportunities.opportunity.leadsSourceId;
      },
      set(value) {
        return this.$store.commit('Opportunities/SET_LEAD_SOURCE_ID', value);
      },
    },
  },

  watch: {
    customFieldsList: {
      handler(value) {
        if (value && value.length > 0) {
          if (this.$route.name === 'AddOpportunity') {
            const fieldValues = value.map((item) => ({
              customFieldId: item.id,
              value: Array.isArray(item.value)
                ? item.value.join(',') : item.value || null,
            }));
            this.$store.commit('Opportunities/SET_OPPORTUNITY_CUSTOM_FIELDS', fieldValues);
          } else if (this.$route.name === 'EditOpportunity' && this.opportunity
        && this.opportunity.customFields) {
            const fieldValues = value.map((item, index) => ({
              customFieldId: this.customFields[index].customFieldId,
              customFieldEntryId: this.customFields[index].customFieldEntryId,
              value: Array.isArray(item.value)
                ? item.value.join(',') : item.value || null,
            }));
            this.$store.commit('Opportunities/SET_OPPORTUNITY_CUSTOM_FIELDS', fieldValues);
          }
        }
      },
      deep: true,
    },

    opportunity: {
      handler() {
        this.mapFields();

        if (this.customFieldsList.length > 0) {
          const customFields = [...this.customFieldsList].map((item, index) => {
            if (item.type === 'MULTI_SELECT') {
              return ({
                ...item,
                value: this.customFields[index]?.value ? this.customFields[index]?.value.split(',') : null,
              });
            }
            return ({
              ...item,
              value: this.customFields[index]?.value && this.customFields[index]?.value.split(',').length > 1
                ? this.customFields[index]?.value.split(',') : this.customFields[index].value || null,
            });
          });
          this.$store.commit('Settings/SET_LEAD_OPP_CUSTOM_FIELDS', customFields);
        }

        if (this.customFieldsList.length > 0 && this.$route.name === 'AddOpportunity') {
          const fieldValues = this.customFieldsList.map((item) => ({
            customFieldEntryId: item.id,
            value: item.value || null,
          }));
          this.$store.commit('Opportunities/SET_OPPORTUNITY_CUSTOM_FIELDS', fieldValues);
        }
      },
    },

    stageId: {
      async handler(value) {
        if (!this.opportunityStages.filter((i) => i.orderSequence === value)[0]) {
          return;
        }

        const { code } = this.opportunityStages.filter((i) => i.orderSequence === value)[0];

        if (code && code.includes('closed') && this.opportunityReasonStages.length > 0) {
          this.isloadingReasons = true;
          this.$store.commit('Opportunities/SET_OPPORTUNITY_CLOSING_REASON', null);
          const stages = this.opportunityReasonStages.filter((i) => i.stageCode === code);
          if (stages.length > 0) {
            const { id } = stages[0];
            this.loading = true;
            await this.$store.dispatch('Opportunities/fetchOpportunityStageReasons', id);
            this.loading = false;
          }
          this.isloadingReasons = false;
        }
      },
    },

    account() {
      this.contactsSearchResults = [];
    },

    accountId() {
      this.setAccount();
    },

    async selectedAccount(value) {
      if (value.id) {
        this.$store.commit('Opportunities/SET_OPPORTUNITY_ACCOUNT_ID', value.id);
        this.$store.commit('Contacts/SET_CONTACT_ACCOUNT_ID', value.id);
        this.$store.commit('Contacts/SET_CONTACT_ACCOUNT', value.name);
      }
    },

    selectedContact(value) {
      if (value.id) {
        this.$store.commit('Opportunities/SET_OPPORTUNITY_PRIMARY_CONTACT_ID', value.id);
      }
    },

    selectedOwner(value) {
      this.$store.commit('Opportunities/SET_OPPORTUNITY_OWNER_ID', value.userId);
    },

    selectedCoOwner(value) {
      this.$store.commit('Opportunities/SET_OPPORTUNITY_CO_OWNER_ID', value.userId);
    },

    selectedProjectFolder(value) {
      if (this.$route.name === 'EditOpportunity') {
        this.$store.dispatch('ProjectFolders/createProjectFolder', {
          opportunityId: this.$route.params.opportunityId,
          projectFolderId: value.id,
        });
        this.$store.commit('Opportunities/ADD_OPPORTUNITY_PROJECT_FOLDER', value);
      } else {
        this.$store.commit('Opportunities/ADD_OPPORTUNITY_PROJECT_FOLDER', value);
      }
    },
  },

  async created() {
    try {
      await this.$store.dispatch('Settings/fetchCustomFields', 'Lead_Opportunity');
      if (this.$route.name === 'EditOpportunity') {
        // await this.$store.dispatch('Settings/fetchCustomFields', 'Lead_Opportunity');
        if (this.$route.query.id) {
          this.$store.commit('Opportunities/SET_UPDATE_OPPORTUNITY', this.$route.query);
        } else {
          await this.fetchOpportunity(this.$route.params.opportunityId);
        }
        const influencersRes = await this.$store.dispatch('DataGrids/fetchInfluencers', {
          opportunityId: this.$route.params.opportunityId,
          page: 0,
          size: 100,
          sort: ['updatedAt', 'desc'],
          filters: '',
        });
        this.$store.commit('Opportunities/SET_OPPORTUNITY_INFLUENCERS', influencersRes);
      }
      this.$store.dispatch('Lookups/fetchAccountTypes');
      this.$store.dispatch('Lookups/fetchOpportunityStages');
      this.$store.dispatch('Lookups/fetchOpportunityTypes');
      this.$store.dispatch('Lookups/fetchTemperatures');
      this.$store.dispatch('Lookups/fetchProbabilities');
      this.$store.dispatch('Lookups/fetchLeadStatuses');
      this.$store.dispatch('Lookups/fetchLeadSources');
      await this.$store.dispatch('Opportunities/fetchOpportunityReasonStages');
      await this.$store.dispatch('Lookups/fetchOpportunityClosingReasons');
      if (this.$route.name === 'EditOpportunity') {
        if (!this.opportunityStages || !this.opportunity.stageId) return;

        const { code } = this.opportunityStages
          .filter((i) => i.orderSequence === this.opportunity.stageId)[0];
        const { id } = this.opportunityReasonStages.filter((i) => i.stageCode === code)[0];
        await this.$store.dispatch('Opportunities/fetchOpportunityStageReasons', id);
        this.$store.commit('Contacts/SET_CONTACT_ACCOUNT_ID', this.opportunity.accountId);
        this.$store.commit('Contacts/SET_CONTACT_ACCOUNT', this.opportunity.accountName);
      }
    } catch (error) {
      console.error(JSON.stringify(error));
    } finally {
      this.isLoading = false;
    }
  },

  destroyed() {
    this.$store.commit('Opportunities/CLEAR_OPPORTUNITY');
    this.$store.commit('Contacts/CLEAR_CONTACT');
  },

  methods: {
    ...mapActions({
      fetchOpportunity: 'Opportunities/fetchOpportunity',
    }),

    openModal(modalLabel) {
      this.modalName = modalLabel;
      this.isComponentModalActive = true;
    },

    async setAccount() {
      this.contactsSearchResults = [];
      this.isLoadingContacts = true;
      const response = await this.$store.dispatch('Contacts/fetchAccountContactList', {
        page: 0,
        size: 1000000,
        sort: ['updatedAt', 'desc'],
        filters: `&${new URLSearchParams({ accountIds: this.accountId, isActive: 'true' }).toString()}`,
      });
      this.contactsSearchResults = response;
      this.isLoadingContacts = false;
    },

    setPrimaryContact(payload) {
      this.$store.commit('Opportunities/SET_OPPORTUNITY_PRIMARY_CONTACT_ID', payload.id);
      this.$store.commit('Opportunities/SET_OPPORTUNITY_PRIMARY_CONTACT', `${payload.firstName} ${payload.lastName}`);
    },

    getCustomFieldFilteredTags(text, field) {
      this.filteredTags = field.lookups.filter((option) => option.value
        .toString()
        .toLowerCase()
        .indexOf(text.toLowerCase()) >= 0);
    },

    getFilteredTags(text) {
      this.filteredTags = this.opportunityTypes.filter((option) => option.value
        .toString()
        .toLowerCase()
        .indexOf(text.toLowerCase()) >= 0);
    },

    mapFields() {
      if (this.customFieldsList && this.customFieldsList.length > 0 && this.$route.name === 'EditOpportunity') {
        const fieldValues = [...this.customFieldsList].map((item) => {
          const filteredArr = [...this.customFields]
            .filter((i) => i.customFieldId === item.id);
          return ({
            customFieldId: item.id,
            customFieldEntryId: filteredArr.length > 0
              ? [...filteredArr][0].customFieldEntryId : null,
            value: filteredArr.length > 0 ? [...filteredArr][0].value : null,
          });
        });
        this.$store.commit('Opportunities/SET_OPPORTUNITY_CUSTOM_FIELDS', fieldValues);
      }
    },

    clearValue(index) {
      const clearedFieldVal = [...this.customFieldsList];
      clearedFieldVal[index].value = '';
      this.$store.commit('Settings/SET_LEAD_OPP_CUSTOM_FIELDS', clearedFieldVal);
    },

    async clearPrimaryContact() {
      const response = await this.$store.dispatch('Contacts/fetchAccountContactList', {
        page: 0,
        perPage: 1,
        sort: ['updatedAt', 'desc'],
        filters: `&${new URLSearchParams({ contactName: 'unassigned' }).toString()}`,
      });

      if (response.content && response.content.length > 0) {
        this.$store.commit(
          'Opportunities/SET_OPPORTUNITY_PRIMARY_CONTACT',
          response[0].contactName.trim(),
        );
        this.$store.commit(
          'Opportunities/SET_OPPORTUNITY_PRIMARY_CONTACT_ID',
          response[0].contactId,
        );
      } else {
        this.$store.commit('Opportunities/SET_OPPORTUNITY_PRIMARY_CONTACT', '');
        this.$store.commit('Opportunities/SET_OPPORTUNITY_PRIMARY_CONTACT_ID', '');
      }
    },

    clearCoOwner() {
      if (this.$route.name === 'EditOpportunity') {
        this.$store.dispatch(
          'Opportunities/deleteOpportunityOwner',
          {
            opportunityId: this.$route.params.opportunityId,
            id: this.$store.state.Opportunities.opportunity.coOwner.opportunityOwnerId,
          },
        );
        this.$store.commit('Opportunities/SET_OPPORTUNITY_CO_OWNER', '');
        this.$store.commit('Opportunities/SET_OPPORTUNITY_CO_OWNER_ID', '');
        this.$store.commit('Opportunities/SET_OPPORTUNITY_CO_OWNER_OWNER_ID', '');
        this.$store.commit('Opportunities/SET_OPPORTUNITY_CO_OWNER_PERCENTAGE', 0);
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_OWNER_PERCENTAGE', 100);
      }
      this.$store.commit('Opportunities/SET_OPPORTUNITY_CO_OWNER', '');
      this.$store.commit('Opportunities/SET_OPPORTUNITY_CO_OWNER_ID', '');
      this.$store.commit('Opportunities/SET_OPPORTUNITY_CO_OWNER_OWNER_ID', '');
      this.$store.commit('Opportunities/SET_OPPORTUNITY_CO_OWNER_PERCENTAGE', 0);
      return this.$store.commit('Opportunities/SET_OPPORTUNITY_OWNER_PERCENTAGE', 100);
    },

    getOwnersAsyncData: debounce(async function (token) {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch('Search/searchUsers', token);
        this.ownerSearchResults = response;
      } catch (error) {
        this.ownerSearchResults = [];
      } finally {
        this.isLoading = false;
      }
    }, 500),

    getProjectFoldersAsyncData: debounce(async function (token) {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch('ProjectFolders/fetchProjectFolders', token);
        this.projectFoldersSearchResults = response.data;
      } catch (error) {
        this.projectFoldersSearchResults = [];
      } finally {
        this.isLoading = false;
      }
    }, 500),

    getCoOwnersAsyncData: debounce(async function (token) {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch('Search/searchUsers', token);
        this.coOwnerSearchResults = response;
      } catch (error) {
        this.coOwnerSearchResults = [];
      } finally {
        this.isLoading = false;
      }
    }, 500),

    getAccountsAsyncData: debounce(async function (token) {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch('DataGrids/fetchAccounts', {
          page: 0,
          size: 50,
          sort: ['createdAt', 'desc'],
          filters: `&${new URLSearchParams({ name: token }).toString()}`,
        });
        this.accountsSearchResults = response;
      } catch (error) {
        this.accountsSearchResults = [];
      } finally {
        this.isLoading = false;
      }
    }, 500),

    getContactsAsyncData: debounce(async function (token) {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch('Contacts/fetchAccountContactList', {
          page: 0,
          size: 1000000,
          sort: ['updatedAt', 'desc'],
          filters: `&${new URLSearchParams({
            accountIds: this.$store.state.Opportunities.opportunity.accountId,
            contactName: token,
          }).toString()}`,
        });
        this.contactsSearchResults = response;
      } catch (error) {
        this.contactsSearchResults = [];
      } finally {
        this.isLoading = false;
      }
    }, 500),

    clearTemperatureId() {
      this.$store.commit('Opportunities/SET_OPPORTUNITY_TEMPERATURE_ID', '');
    },

    async removeProjectFolder(projectFolder) {
      try {
        if (this.$route.name === 'EditOpportunity') {
          await this.$store.dispatch('ProjectFolders/removeProjectFolder', {
            opportunityId: this.$route.params.opportunityId,
            projectFolderId: projectFolder.id,
          });
          this.$store.commit(
            'Opportunities/REMOVE_OPPORTUNITY_PROJECT_FOLDER', projectFolder.projectId,
          );
        } else {
          this.$store.commit(
            'Opportunities/REMOVE_OPPORTUNITY_PROJECT_FOLDER', projectFolder.projectId,
          );
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="css" scoped>
  .link {
    color: #1475be;
    font-weight: 600;
  }
</style>
