<template>
  <validation-observer
    ref="contactsForm"
    v-slot="{ invalid, handleSubmit }"
  >
    <form @submit.prevent="handleSubmit(createContact)">
      <!-- loading component -->
      <b-loading
        v-model="isLoading"
        :is-full-page="true"
        :can-cancel="false"
      />

      <!-- page header component -->
      <page-header
        name="add contact"
        icon="account-group"
      >
        <template #actions>
          <div class="level-item">
            <b-field>
              <b-button
                :disabled="invalid"
                native-type="submit"
                type="is-success"
                icon-left="content-save"
              >
                create contact
              </b-button>
            </b-field>
          </div>
        </template>
      </page-header>

      <section class="section">
        <!-- error message component -->
        <b-message
          v-model="isInvalid"
          type="is-warning"
          has-icon
        >
          {{ message }}
        </b-message>

        <div class="container">
          <div class="columns">
            <div class="column is-three-fifths">
              <contacts-form :contact-types="contactTypes" />
            </div>
            <div class="column">
              <div class="card is-primary">
                <header class="card-header">
                  <p class="card-header-title">
                    membership
                  </p>
                </header>
                <div class="card-content">
                  <contacts-membership-form />
                </div>
              </div>
            </div>
          </div>

          <b-field>
            <b-button
              :disabled="invalid"
              native-type="submit"
              type="is-success"
              icon-left="content-save"
              value="create contact"
            >
              create contact
            </b-button>
          </b-field>
        </div>
      </section>
    </form>
  </validation-observer>
</template>

<script>
import { mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';

import { ContactsForm, ContactsMembershipForm } from '@/components/Contacts';
import { PageHeader } from '@/components/Shared';

export default {

  name: 'AddContact',

  components: {
    ValidationObserver,
    ContactsForm,
    ContactsMembershipForm,
    PageHeader,
  },

  data: () => ({
    isLoading: false,
    isInvalid: false,
    message: '',
  }),

  computed: mapGetters({
    contactTypes: 'Lookups/getContactTypes',
    contact: 'Contacts/getContact',
    date: 'Contacts/getDOBDate',
    month: 'Contacts/getDOBMonth',
  }),

  beforeCreate() {
    this.$store.commit('Contacts/CLEAR_CONTACT');
  },

  async created() {
    try {
      await this.$store.dispatch('Settings/fetchCustomFields', 'Contact');
      await this.$store.dispatch('Lookups/fetchContactTypes');
    } catch (error) {
      console.error(error);
    }
  },

  mounted() {
    if (this.$route.query.accountId) {
      this.$store.commit('Contacts/SET_CONTACT_ACCOUNT_ID', this.$route.query.accountId);
      this.$store.commit('Contacts/SET_CONTACT_ACCOUNT', this.$route.query.account);
    }
  },

  methods: {
    async createContact() {
      this.isLoading = true;

      try {
        const payload = { ...this.contact };
        payload.dob = this.month ? `${this.month}-${this.date}` : '';
        await this.$store.dispatch('Contacts/createContact', payload);
        if (this.$route.query.accountId) {
          this.$router.push(`/accounts/${this.$route.query.accountId}/view?activeTab=1`);
        } else {
          this.$router.push('/contacts');
        }
      } catch (error) {
        this.isInvalid = true;
        this.message = error.message;
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="css" scoped>
</style>
