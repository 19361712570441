<template>
  <div>
    <div class="columns">
      <div class="column is-half">
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <p class="has-text-weight-bold is-capitalized">
                Opportunity #
              </p>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="isLoading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>
              <span
                v-else
                class="has-text-grey"
              >
                {{ opportunity.id }}
              </span>
            </div>
          </div>
        </div>

        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <p class="has-text-weight-bold is-capitalized">
                Account
              </p>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="isLoading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>

              <template v-else>
                <router-link
                  :to="{ name: 'ViewAccount', params: { accountId: opportunity.accountId } }"
                  class="has-text-info has-text-weight-semibold"
                >
                  {{ opportunity.accountName }}
                </router-link>
              </template>
            </div>
          </div>
        </div>

        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <p class="has-text-weight-bold is-capitalized">
                Contact
              </p>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="isLoading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>

              <template v-else>
                <router-link
                  :to="{ name: 'ViewContact', params: { contactId: opportunity.primaryContactId } }"
                  class="has-text-info has-text-weight-semibold"
                >
                  {{ opportunity.contactName }}
                </router-link>
              </template>
            </div>
          </div>
        </div>

        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <p class="has-text-weight-bold is-capitalized">
                Phone
              </p>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="isLoading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>

              <template v-else>
                <span class="has-text-grey">
                  {{ opportunity.contactPhone }}
                </span>
              </template>
            </div>
          </div>
        </div>

        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <p class="has-text-weight-bold is-capitalized">
                Email
              </p>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="isLoading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>

              <template v-else>
                <span class="has-text-grey">
                  {{ opportunity.contactEmail }}
                </span>
              </template>
            </div>
          </div>
        </div>

        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <p class="has-text-weight-bold is-capitalized">
                Industry
              </p>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="isLoading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>

              <template v-else>
                <span class="has-text-grey">
                  {{ opportunity.industry }}
                </span>
              </template>
            </div>
          </div>
        </div>

        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <p class="has-text-weight-bold is-capitalized">
                Closing Reason
              </p>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="isLoading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>

              <template v-else>
                <span class="has-text-grey">
                  {{ opportunity.reason }}
                </span>
              </template>
            </div>
          </div>
        </div>

        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <p class="has-text-weight-bold is-capitalized">
                Type
              </p>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="isLoading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>

              <template v-else>
                <span class="has-text-grey">
                  {{ opportunity.opportunityTypes
                    ? opportunity.opportunityTypes.split(',').join(', ') : '--' }}
                </span>
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <p class="has-text-weight-bold is-capitalized">
                Bid Due Date
              </p>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="isLoading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>

              <template v-else />
              <span
                v-if="opportunity.estimatedPotentials && opportunity.estimatedPotentials.bidDueDate"
                class="has-text-grey"
              >
                {{ opportunity.estimatedPotentials.bidDueDate | date }}
              </span>
            </div>
          </div>
        </div>

        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <p class="has-text-weight-bold is-capitalized">
                Est. Booking Date
              </p>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="isLoading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>

              <template v-else>
                <span
                  v-if="opportunity.estimatedPotentials
                    && opportunity.estimatedPotentials.estBookingDate"
                  class="has-text-grey"
                >
                  {{ opportunity.estimatedPotentials.estBookingDate | date }}
                </span>
              </template>
            </div>
          </div>
        </div>

        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <p class="has-text-weight-bold is-capitalized">
                Est. Install Date
              </p>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="isLoading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>

              <template v-else>
                <span
                  v-if="opportunity.estimatedPotentials
                    && opportunity.estimatedPotentials.estInstallDate"
                  class="has-text-grey"
                >
                  {{ opportunity.estimatedPotentials.estInstallDate | date }}
                </span>
              </template>
            </div>
          </div>
        </div>

        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <p class="has-text-weight-bold is-capitalized">
                Est. Invoice Date
              </p>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="isLoading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>

              <template v-else>
                <span
                  v-if="opportunity.estimatedPotentials
                    && opportunity.estimatedPotentials.estInvoiceDate"
                  class="has-text-grey"
                >
                  {{ opportunity.estimatedPotentials.estInvoiceDate | date }}
                </span>
              </template>
            </div>
          </div>
        </div>

        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <p class="has-text-weight-bold is-capitalized">
                Lead Source
              </p>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="isLoading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>

              <template v-else>
                <span
                  v-if="opportunity.leadsSource"
                  class="has-text-grey"
                >
                  {{ opportunity.leadsSource }}
                </span>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="divider">

    <div class="columns">
      <div class="column is-two-thirds-tablet">
        <div class="content">
          <h4 class="subtitle has-text-primary is-capitalized">
            Custom Fields
          </h4>
        </div>
        <div
          v-for="(item, key) in customFieldsList"
          :key="key"
          class="level is-mobile"
        >
          <div class="level-left">
            <div class="level-item">
              <p class="has-text-weight-bold is-capitalized">
                {{ item.label }}
              </p>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="isLoading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>

              <template v-else>
                <span
                  v-if="opportunity.customFieldEntries"
                  class="has-text-grey"
                >
                  <template
                    v-if="opportunity.customFieldEntries
                      .filter((i) => i.customField.id === item.id).length > 0"
                  >
                    <template v-if="item.type === 'CURRENCY'">
                      {{ opportunity.customFieldEntries.filter(
                        (i) => i.customField.id === item.id)[0].value | currency }}
                    </template>
                    <template v-else>
                      {{ opportunity.customFieldEntries
                        .filter((i) => i.customField.id === item.id)[0].value.split(',').length > 1
                        ? opportunity.customFieldEntries
                          .filter((i) => i.customField.id === item.id)[0].value.split(',')
                          .join(', ')
                        : opportunity.customFieldEntries
                          .filter((i) => i.customField.id === item.id)[0].value }}
                    </template>
                  </template>
                  <template
                    v-else
                  >
                    {{ '--' }}
                  </template>
                </span>
                <span
                  v-else
                  class="has-text-grey"
                >
                  {{ '-' }}
                </span>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OpportunitiesDetails',
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },

    opportunity: {
      type: Object,
      required: true,
    },

    opportunityTypes: {
      type: Array,
      required: true,
    },

    closingReasons: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="css" scoped></style>
