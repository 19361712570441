<template>
  <div>
    <!-- page header component -->
    <page-header
      name="contacts"
      icon="account-group"
    >
      <template #actions>
        <div class="level-item">
          <search-bar />
        </div>

        <div class="level-item">
          <b-dropdown aria-role="list">
            <b-button
              slot="trigger"
              type="is-info"
              icon-left="plus"
              icon-right="chevron-down"
            >
              create
            </b-button>
            <template v-if="hasPermissions('create_accounts')">
              <b-dropdown-item
                aria-role="listitem"
                class="has-text-grey"
                @click="$router.push('/accounts/add')"
              >
                Account
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <template v-if="hasPermissions('create_contacts')">
              <b-dropdown-item
                aria-role="listitem"
                class="has-text-grey"
                @click="$router.push('/contacts/add')"
              >
                Contact
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <template v-if="hasPermissions('create_leads')">
              <b-dropdown-item
                aria-role="listitem"
                class="has-text-grey"
                @click="$router.push('/leads/add')"
              >
                Lead
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <template v-if="hasPermissions('create_leads')">
              <b-dropdown-item
                aria-role="listitem"
                class="has-text-grey"
                @click="$router.push('/opportunities/add')"
              >
                Opportunity
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <b-dropdown-item
              v-if="hasPermissions('create_activities')"
              has-link
              aria-role="menuitem"
            >
              <router-link to="/activities/add">
                Activity
              </router-link>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </page-header>

    <!-- contacts table component -->
    <section class="section">
      <div class="container">
        <b-breadcrumb size="is-small">
          <b-breadcrumb-item
            tag="router-link"
            to="/profile"
          >
            Home
          </b-breadcrumb-item>
          <b-breadcrumb-item
            tag="router-link"
            to="/contacts"
            active
          >
            Contacts
          </b-breadcrumb-item>
        </b-breadcrumb>

        <contacts-table module="contacts" />
      </div>
    </section>
  </div>
</template>

<script>
import { PageHeader } from '@/components/Shared';
import { ContactsTable } from '@/components/Contacts';
import { SearchBar } from '@/components/Search';

export default {

  name: 'ListContacts',

  components: {
    PageHeader,
    ContactsTable,
    SearchBar,
  },

};
</script>

<style lang="css" scoped>
</style>
